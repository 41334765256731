<template>
  <v-container style="height: 100%; width: 100%">
    <div
      tabindex="0"
      v-on:keydown="onKeydown"
      v-on:keyup="isTyping = false"
      style="height: 100%; width: 100%; border: none !important"
      ref="typeArea"
    >
      <v-row class="text-center">
        <v-col cols="12" style="font-size: 288px">{{ lastKey }}</v-col>

        <v-col class="mb-4">
          <template v-for="item in history">{{ item }}</template>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "TippSpiel",
  msg: null,

  data: () => ({
    lastKey: "",
    history: [],
    isTalking: false,
    isTyping: false,
    keyMapping: [
      {
        key: "a",
        sayPrefix: "A wie",
        say: ["Anna", "Apfel", "Alles", "Alt", "Angel", "Ampel"],
        show: "A",
      },
      {
        key: "b",
        sayPrefix: "B wie",
        say: ["Bela", "Ball", "Blau"],
        show: "B",
      },
      {
        key: "c",
        sayPrefix: "C wie",
        say: ["Computer", "Chamäleon"],
        show: "C",
      },
      {
        key: "d",
        sayPrefix: "D wie",
        say: ["Dinosaurier"],
        show: "D",
      },
      {
        key: "e",
        sayPrefix: "E wie",
        say: ["Esel", "Erste", "Engel"],
        show: "E",
      },
      {
        key: "f",
        sayPrefix: "F wie",
        say: ["Fuchs", "Finger", "Fisch", "Frosch", "Fratze"],
        show: "F",
      },
      {
        key: "g",
        sayPrefix: "G wie",
        say: ["Gurke", "Gerne", "Gleich", "Groß", "Glück"],
        show: "G",
      },
      {
        key: "h",
        sayPrefix: "H wie",
        say: ["Hund", "Hallo", "Hier", "Holz", "Hinten"],
        show: "H",
      },
      {
        key: "i",
        sayPrefix: "I wie",
        say: ["Igel", "Interessant", "Immer", "Insel"],
        show: "I",
      },
      {
        key: "j",
        sayPrefix: "J wie",
        say: ["Jonas", "Junge", "Jetzt"],
        show: "J",
      },
      {
        key: "k",
        sayPrefix: "K wie",
        say: ["Katja", "Katze", "Kinder", "Kuss"],
        show: "K",
      },
      {
        key: "l",
        sayPrefix: "L wie",
        say: ["Luchs", "Löwe", "Lustig", "Liebe"],
        show: "L",
      },
      {
        key: "m",
        sayPrefix: "M wie",
        say: ["Mama", "Mund", "Mauer", "Möbel", "Matschepfütze"],
        show: "M",
      },
      {
        key: "n",
        sayPrefix: "N wie",
        say: ["Nein"],
        show: "N",
      },
      {
        key: "o",
        sayPrefix: "O wie",
        say: ["Omi", "Onkel", "Ohr", "Oben"],
        show: "O",
      },
      {
        key: "p",
        sayPrefix: "P wie",
        say: ["Papa", "Pause", "Platz", "Platsch", "Pfütze"],
        show: "P",
      },
      {
        key: "q",
        sayPrefix: "Q wie",
        say: ["Quatsch", "Quark"],
        show: "Q",
      },
      {
        key: "r",
        sayPrefix: "R wie",
        say: ["Richard", "Ring", "Rund", "Richtig", "Regentropfen"],
        show: "R",
      },
      {
        key: "s",
        sayPrefix: "S wie",
        say: ["Summ Summ Summ", "Sonne", "Sand", "Spielen", "Spielzeug"],
        show: "S",
      },
      {
        key: "t",
        sayPrefix: "T wie",
        say: ["Tim", "Toll", "Tauchen", "Trinken", "Tropfen"],
        show: "T",
      },
      {
        key: "u",
        sayPrefix: "U wie",
        say: ["Uhu", "Unten"],
        show: "U",
      },
      {
        key: "v",
        sayPrefix: "V wie",
        say: ["Vogel", "Voll"],
        show: "V",
      },
      {
        key: "w",
        sayPrefix: "W wie",
        say: ["Wolke", "Wissen", "Wurst", "Wolf"],
        show: "W",
      },
      {
        key: "x",
        sayPrefix: "X wie",
        say: ["Xylophon"],
        show: "X",
      },
      {
        key: "y",
        sayPrefix: "Y wie",
        say: ["Y"],
        show: "Y",
      },
      {
        key: "z",
        sayPrefix: "Z wie",
        say: ["Zoo", "Zebra", "Zimmer"],
        show: "Z",
      },
      {
        key: "ä",
        sayPrefix: "Ä wie",
        say: ["Ätsch", "Älter"],
        show: "Ä",
      },
      {
        key: "ö",
        sayPrefix: "Ö wie",
        say: ["Öfter"],
        show: "Ö",
      },
      {
        key: "ü",
        sayPrefix: "Ü wie",
        say: ["Über"],
        show: "Ü",
      },
      {
        key: "0",
        say: ["0"],
        show: "0",
      },
      {
        key: "1",
        say: ["1"],
        show: "1",
      },
      {
        key: "2",
        say: ["2"],
        show: "2",
      },
      {
        key: "3",
        say: ["3"],
        show: "3",
      },
      {
        key: "4",
        say: ["4"],
        show: "4",
      },
      {
        key: "5",
        say: ["5"],
        show: "5",
      },
      {
        key: "6",
        say: ["6"],
        show: "6",
      },
      {
        key: "7",
        say: ["7"],
        show: "7",
      },
      {
        key: "8",
        say: ["8"],
        show: "8",
      },
      {
        key: "9",
        say: ["9"],
        show: "9",
      },
    ],
  }),

  methods: {
    ensureMsg() {
      if (!this.msg) {
        this.msg = new SpeechSynthesisUtterance();
        const voices = this.speechSynthesis.getVoices();
        console.log("voices:", voices);
        let voice = voices.find(
          (voice) => voice.lang === "de-DE" && voice.name === "Google Deutsch"
        );
        if (!voice) {
          voice = voices.find((voice) => voice.lang === "de-DE");
        }
        console.log("chosen voice:", voice);
        this.msg.volume = 1; // 0 to 1
        this.msg.rate = 1; // 0.1 to 10
        this.msg.pitch = 0; //0 to 2
        this.msg.lang = voice.lang;

        this.msg.onend = () => {
          this.isTalking = false;
        };

        this.msg.onerror = () => {
          this.isTalking = false;
        };
      }
    },

    onKeydown(key) {
      if (this.isTyping) {
        return;
      }

      this.speechSynthesis.cancel();

      this.isTyping = true;

      if (key.key === "Backspace" || key.key === "Delete") {
        this.lastKey = "";
        this.history = [];
      }

      this.ensureMsg();

      if (!this.msg) {
        return;
      }

      console.log("keyDown:", key);

      const mappedKey = this.keyMapping.find(
        (mapping) => mapping.key === key.key.toLowerCase()
      );

      if (!mappedKey) {
        console.log("no mapped key found");
        return;
      }

      console.log("mapped key:", mappedKey);

      if (!mappedKey.say || mappedKey.say.length === 0) {
        return;
      }

      const index = mappedKey.index ? mappedKey.index : 0;

      let say =
        (mappedKey.sayPrefix ? mappedKey.sayPrefix + " " : "") +
        mappedKey.say[index];

      if (say !== "") {
        this.isTalking = true;
        this.msg.text = say;
        this.speechSynthesis.speak(this.msg);
      }

      if (mappedKey.show !== "") {
        this.lastKey = mappedKey.show;
        this.history.push(mappedKey.show);
      }

      mappedKey.index = index + 1;

      if (mappedKey.index >= mappedKey.say.length) {
        mappedKey.index = 0;
      }
    },
  },

  created() {
    // this.$refs.typeArea.focus();
  },
};
</script>
